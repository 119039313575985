import toLower from 'lodash/toLower';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';

import { aclService } from '@emobg/access-utils';
import { FALLBACK_MESSAGE, isFalsyString } from '@emobg/web-utils';
import { COLORS, GRAYSCALE } from '@emobg/web-components';
import { ALGOLIA_REFINEMENT_OPTIONS } from '@emobg/vue-internal';
import { useTranslations } from '@/composable/App/Translations';
import { DRIVING_LICENSE_STATUS } from '@/constants/drivingLicenseStatus';
import { BADGE_STATUS } from '@/constants/badgeStatus';
import { EMPLOYEE_STATUS } from '@/constants/employeeStatus';

import EmployeeEmail from '@/components/EmployeeList/EmployeeEmail';
import EmployeePhone from '@/components/EmployeeList/EmployeePhone';
import AlgoliaTableItemActions from '@/components/AlgoliaTableItemActions/AlgoliaTableItemActions';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

import {
  concatFilterByPermission,
  createFilterByPermissions,
} from '@/utils/algoliaHelpers';

import { PERMISSIONS } from '@/constants/userPermissions';

import CostAllocationDetailComponent from '../CostAllocationDetailComponent';

const { $t } = useTranslations();

const indexAttributes = {
  dedicatedFleetStatus: 'dedicated_fleet_status',
  drivingLicenseReview: 'driving_license_review',
  badgeStatus: 'badge_status',
  badgeNumber: 'badge_number',
  location: 'location',
};

const employeeStatusTitle = status => (!isNil(status) ? $t(`business_profile.status.${toLower(status)}`) : status);

const drivingLicenseStatusTitle = status => (!isNil(status) ? $t(`business_profile.license_status.${status}`) : status);

const keyCardStatusTitle = status => (!isNil(status) ? $t(`business_profile.employee.keycard_status_${status}`) : status);

export const getFacetsConfigs = ({
  isCsOperatorUsingBadges,
  isCsOperatorUsingRestrictiveBadgeProvider,
}) => [
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: $t('business_profile.employee.facets.status'),
      attributeName: indexAttributes.dedicatedFleetStatus,
      placeholder: `${$t('personal_profile.action.select')} ${$t('business_profile.employee.facets.status')}`,
      transformValue: employeeStatusTitle,
    },
    isVisible: true,
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: $t('business_profile.employee.facets.driving_license_status'),
      attributeName: indexAttributes.drivingLicenseReview,
      placeholder: `${$t('personal_profile.action.select')} ${$t('business_profile.employee.facets.driving_license_status')}`,
      transformValue: drivingLicenseStatusTitle,
    },
    isVisible: true,
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: $t('business_profile.employee.facets.keycard_status'),
      placeholder: `${$t('personal_profile.action.select')} ${$t('business_profile.employee.facets.keycard_status')}`,
      attributeName: indexAttributes.badgeStatus,
      transformValue: keyCardStatusTitle,
    },
    isVisible: isCsOperatorUsingBadges && isCsOperatorUsingRestrictiveBadgeProvider,
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: $t('business_profile.employee.facets.location'),
      attributeName: indexAttributes.location,
      transformValue: location => (isFalsyString(location) ? FALLBACK_MESSAGE.noData : location),
      placeholder: `${$t('personal_profile.action.select')} ${$t('business_profile.employee.facets.location')}`,
    },
    isVisible: true,
  },
];

const drivingLicenseStatusText = status => (isNil(status) ? FALLBACK_MESSAGE.dash : $t(`business_profile.license_status.${status}`));

const keyCardStatusText = status => (isNil(status) ? FALLBACK_MESSAGE.dash : $t(`business_profile.employee.keycard_status_${status}`));

const employeeStatusText = status => (isNil(status) ? FALLBACK_MESSAGE.dash : $t(`business_profile.status.${toLower(status)}`));

const drivingLicenseStatusColor = status => {
  const statusColor = {
    [DRIVING_LICENSE_STATUS.missing]: COLORS.warning,
    [DRIVING_LICENSE_STATUS.waitingForValidation]: COLORS.warning,
    [DRIVING_LICENSE_STATUS.rejected]: COLORS.danger,
    [DRIVING_LICENSE_STATUS.expired]: COLORS.danger,
    [DRIVING_LICENSE_STATUS.validated]: COLORS.success,
    [DRIVING_LICENSE_STATUS.toBeReview]: COLORS.warning,
  };

  return statusColor[status] || GRAYSCALE.ground;
};

const keyCardStatusColor = status => {
  const statusColor = {
    [BADGE_STATUS.enabled]: COLORS.success,
    [BADGE_STATUS.missing]: COLORS.warning,
  };

  return statusColor[status] || GRAYSCALE.ground;
};

const employeeStatusColor = status => {
  const statusColor = {
    [EMPLOYEE_STATUS.activated]: COLORS.success,
    [EMPLOYEE_STATUS.badge]: COLORS.warning,
    [EMPLOYEE_STATUS.preRegistered]: COLORS.warning,
    [EMPLOYEE_STATUS.unpaid]: COLORS.warning,
    [EMPLOYEE_STATUS.blocked]: COLORS.danger,
  };

  return statusColor[status] || GRAYSCALE.ground;
};

export const getTableConfig = ({
  csOperatorUsesDrivingLicenseReview,
  isCsOperatorUsingBadges,
  isCsOperatorUsingRestrictiveBadgeProvider,
  rowActions,
}) => [
  {
    title: $t('business_profile.employee.name'),
    transformResult: result => `${result.user_first_name} ${result.user_last_name}`,
    width: 130,
    isVisible: true,
    masked: true,
  },
  {
    title: $t('business_profile.employee.status'),
    component: BadgeComponent,
    props: ({ dedicated_fleet_status: dedicatedFleetStatus }) => ({
      text: employeeStatusText(dedicatedFleetStatus),
      color: employeeStatusColor(dedicatedFleetStatus),
    }),
    width: 100,
    isVisible: true,
  },
  {
    title: $t('business_profile.employee.license_status'),
    component: BadgeComponent,
    props: ({ driving_license_review: drivingLicenseReview }) => ({
      text: drivingLicenseStatusText(drivingLicenseReview),
      color: drivingLicenseStatusColor(drivingLicenseReview),
    }),
    width: 100,
    isVisible: csOperatorUsesDrivingLicenseReview,
  },
  {
    title: $t('business_profile.employee.keycard_status'),
    component: BadgeComponent,
    props: ({ badge_status: badgeStatus }) => ({
      text: keyCardStatusText(badgeStatus),
      color: keyCardStatusColor(badgeStatus),
    }),
    width: 60,
    isVisible: isCsOperatorUsingBadges && isCsOperatorUsingRestrictiveBadgeProvider,
  },
  {
    title: $t('business_profile.employee.email'),
    component: EmployeeEmail,
    props: ({ email }) => ({
      email,
    }),
    width: 230,
    isVisible: true,
  },
  {
    title: $t('business_profile.employee.location'),
    property: indexAttributes.location,
    width: 100,
    isVisible: true,
    folded: true,
  },
  {
    title: $t('business_profile.employee.phone'),
    component: EmployeePhone,
    props: ({ phone }) => ({
      phone,
    }),
    width: 150,
    isVisible: true,
    folded: true,
  },
  {
    title: $t('business_profile.employee.keycard'),
    property: indexAttributes.badgeNumber,
    width: 150,
    isVisible: isCsOperatorUsingBadges && isCsOperatorUsingRestrictiveBadgeProvider,
    folded: true,
  },
  {
    title: $t('business_profile.employee.cost_allocations'),
    component: CostAllocationDetailComponent,
    props: ({ cost_allocation: costAllocation }) => ({
      costAllocation: isArray(costAllocation) ? costAllocation : [],
    }),
    width: 150,
    isVisible: true,
    folded: true,
  },
  {
    title: '',
    component: AlgoliaTableItemActions,
    props: result => ({ algoliaContext: result, rowActions }),
    width: 50,
    isVisible: true,
  },
];

export const getEmployeesTableFilter = ({ companyUuid }) => {
  const uuids = createFilterByPermissions(
    aclService,
    PERMISSIONS.VIEW_EMPLOYEES,
  );

  const finalFilter = concatFilterByPermission({
    currentFilter: `company_uuid:'${companyUuid}'`,
    circlesUuids: uuids,
    field: 'circles',
  });

  return `${finalFilter} AND NOT dedicated_fleet_status:'${EMPLOYEE_STATUS.anonymized}'`;
};
